import { Helmet } from "react-helmet-async";
import { createFreshChatScript } from "./fresh-chat-script";

export const FreshChat = () => {
    const FRESH_CHAT_BASE_URL = process.env.REACT_APP_FRESH_CHAT_BASE_URL;

    return (
        <>
            <Helmet>
                <script
                    id="Freshchat-js-sdk"
                    async
                    src={`${FRESH_CHAT_BASE_URL}/js/widget.js`}
                ></script>
            </Helmet>
            {createFreshChatScript()}
        </>
    );
};
