const FRESH_CHAT_BASE_URL = process.env.REACT_APP_FRESH_CHAT_BASE_URL;
const PAYMENT_LINK = process.env.REACT_APP_PAYMENT_LINK;
const PAYMENT_TOKEN = process.env.REACT_APP_PAYMENT_TOKEN;

const scriptContent = `
        const target = "${PAYMENT_LINK}";

        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json", // Set the content type based on your API requirements
                // Add any other headers if needed
            },
        };

        let token = null;

        fetch(target, options)
            .then((response) => {
                return response.json();
            })
        .then((data) => {
          // Handle the data returned from the server
          token = data.data;
        })
        .catch((error) => {
          // Handle errors during the fetch
        });

        function initFreshChat() {
            window.fcWidget.init({
                token: "${PAYMENT_TOKEN}",
                host: "${FRESH_CHAT_BASE_URL}",
            });

            window.fcWidget.user.setProperties({
                origin: token,
            });

            window.fcWidget.on("user:created", async (resp) => {
                if (resp?.success) {
                    const item = await window.fcWidget.user.get();
                    const iframe = document.querySelector("iframe");
                    iframe.contentWindow.postMessage({ chid: item.data.alias }, "*");
                    window.window.chid = item.data.alias;
                }
            });

            window.fcWidget.on("widget:opened", async (resp) => {
                document.body.style.overflow = "hidden";
            });

            window.fcWidget.on("widget:closed", async (resp) => {
                document.body.style.overflow = "auto";
            });
        }

        function handleOpen() {
            const res = window.fcWidget.isOpen ? null : initFreshChat();
        }

        function initialize(i, t) {
            var e;
            i.getElementById(t)
                ? initFreshChat()
                : (((e = i.createElement("script")).id = t),
            (e.async = !0),
            (e.src = "${FRESH_CHAT_BASE_URL}/js/widget.js"),
            (e.onload = initFreshChat),
            i.head.appendChild(e));
        }

        initialize(document, "Freshchat-js-sdk");
    `;

const FRESH_CHAT_SCRIPT_ID = "fresh-chat-script";

export const createFreshChatScript = () => {
    if (!document.getElementById(FRESH_CHAT_SCRIPT_ID)) {
        const script = document.createElement("script");
        script.id = FRESH_CHAT_SCRIPT_ID;
        script.async = true;
        script.type = "text/javascript";
        script.textContent = scriptContent;
        document.body.appendChild(script);
    }
};
