import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useCookies } from "react-cookie";

import { MY_ACCOUNT_PATH } from "router/constants";
import { handleChatButtonClick } from "utils/chat-button-click";

import { Loader } from "./loader.component";
import { IframeMessageType } from "enums";
import { IframeMessage } from "types";
import { updateChat } from "utils/update-chat";

interface Props {
  title: string;
  link: string;
  id?: string;
}

export const IframeContainer: FC<Props> = ({ title, link, id }) => {
  const location = useLocation();

  const [cookies] = useCookies();

  const [isLoaderVisible, setIsLoaderVisible] = useState(true);

  const isMyAccountPage = location.pathname === MY_ACCOUNT_PATH;

  const isUserLoggedInToPortal = cookies.userLoggedIn;

  const hideLoader = () => setIsLoaderVisible(false);

  const handleMessage = (event: MessageEvent<IframeMessage>) => {
    const { type, value, token } = event.data;

    switch (type) {
      case IframeMessageType.REDIRECT:
        window.location.href = value as string;
        break;
      case IframeMessageType.SCROLL:
        window.scrollTo({
          top: value === "toTop" ? 0 : document.documentElement.scrollHeight,
          behavior: "smooth",
        });
        break;
      case IframeMessageType.OPEN_CHAT:
        handleChatButtonClick();
        break;
      case IframeMessageType.TOKEN:
        updateChat(token)
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => window.removeEventListener("message", handleMessage);
  }, []);

  return (
    <div
      className={twMerge(
        "h-[100dvh] relative pt-20 bg-dark",
        isMyAccountPage && isUserLoggedInToPortal && "md:pt-0"
      )}
    >
      <Loader isVisible={isLoaderVisible} />
      <iframe
        title={title}
        id={id}
        className="w-full h-full"
        src={link}
        onLoad={hideLoader}
        allow="clipboard-write; clipboard-read"
      />
    </div>
  );
};
